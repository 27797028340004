import React, { useEffect, useRef, useState } from 'react';
import Paper from '@mui/material/Paper';
import AddToCart from './Cart/add-to-cart';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box} from '@mui/material';
import InformaticBCAA from './Information/InformaticBCAA';
import InformaticBEE from './Information/InformaticBEE';
import InformaticProtein from './Information/InformaticProtein';
import CircularProgress from '@mui/joy/CircularProgress';














function getCurrentVariantObject(vars: any, id: any) {
  return vars.edges?.filter((v: any) => {
    return v.node.id === id;
  })[0];
}


function VariantForm({ vars, current, pick, setQ }: any) {

 
  return (
    <form className="addToCart">
      {vars?.length > 1 &&
        vars.map((v: any, index: any) => {
          return (
            <div className="product-page-price" key={`variant${index}`}>
              <label>
                <input
                  name="Product Variant"
                  type="radio"
                  id={v.node.id}
                  defaultChecked={index === 0}
                  onChange={() => {
                    pick(v.node.id);
                    
                  }}
                />
                {` ${v.node.title}`}
              </label>
              <br />
            </div>
          );
        })}
      <input
        type="number"
        placeholder="Quantity"
        defaultValue={1}
        min={1}
        max={getCurrentVariantObject(vars, current)?.node?.quantityAvailable}
        onChange={(e) => {
          setQ(parseInt(e.target.value));
        }}
        style={{padding: "10px", fontSize:"120%" }}
       
      />


      
     
      
       
      
    </form>
  );
}

export default function ProductPageContent({ product, images, productVariants, ref} : any) {


    



  // Chosen variant ID
  const [chosenVariant, setChosenVariant] = useState<any>(product);
  // const [productItem, setProductItem] = useState<any>(product.id);
  // Quantity of the chosen variant
  const [quantity, setQuantity] = useState<any>(1);
  const [cost, setCost] = useState<any>('');
  const [selectedImg, setSelectedImg] = useState<any>();
  const [value, setValue] =useState('1');
  const [loading, setLoading] = useState<boolean>(true)
  const counter = useRef(0);

  const imageLoaded = () => {
    counter.current += 1;
    if (counter.current >= images.length) {
      setLoading(false);
    }
  }


  const [answer, setAnswer] = useState<any>();


  
  

  console.log(productVariants)
  
  useEffect(() => {
    // let variantPrice = getCurrentVariantObject(vars, productItem)?.priceRange.maxVariantPrice
    //   .amount;
    setCost(productVariants.price * quantity);
    
      const test = localStorage.getItem("ageAnswer")
  
      if (test) {
        const tester = test;
        setAnswer(tester);
      }
    

      function storageEventHandler(event) {
        if (event.key === "ageAnswer") {
            const todos = event.newValue;
            setAnswer(todos);
        }
    }
    // Hook up the event handler
    window.addEventListener("storage", console.log);
    return () => {
        // Remove the handler when the component unmounts
        window.removeEventListener("storage", storageEventHandler);
    };
    
   
    
    
    


    

    
 

    
  }, [chosenVariant, quantity, cost, productVariants.price, answer])

  
  
  let image = images.map((img:any) => img.src);
  const selectImage = (img) => {
    setSelectedImg(img);
  }

  // let handleAddToCart = async () => {
  //   console.log('--- Adding to cart ---');

  //   const localCart = window.localStorage.getItem('astroCartId');

  //   const body = {
  //     cartId: localCart || '',
  //     itemId: chosenVariant,
  //     quantity: quantity,
  //   };

  //   const cartResponse = await fetch(
  //     `$https://www.statuesque-stardust-11f496.netlify.app/.netlify/functions/add-to-cart`,
  //     {
  //       method: 'post',
  //       body: JSON.stringify(body),
  //       headers: { 'Content-Type': 'application/json' },
  //     }
  //   );

  //   const data = await cartResponse.json();
  //   window.localStorage.setItem('astroCartId', data.id);
  //   return data;
  // };
  const handleChangeTabs = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    setTop(0)
    setSelectedImg(image);
  };
  const handleOpen = () => {
    setOpen(true);
    setTop(200)
  };
  const [top, setTop] = useState<any>(0);
  
 



  
  // const handleChange = (event: SelectChangeEvent) => {
  //   setSize(event.target.value);
  //   console.log(size)
  // };
  


  // const [id, setId] = useState<any>(chosenVariant);

  // console.log(product.variants.edges)
  // function handleChange(event: any) {
  //   setId(event.target.value);
  // }


  // async function addToCart() {
  //   let localCartData = JSON.parse(
  //     window.localStorage.getItem('jamstackconf:shopify:cart') || '{}',
  //   );

    

  //   if (!localCartData.cartId) {
  //     console.error('There was error loading your cart');
  //     return;
  //   }

  //   const result = await fetch('https://armdactivelabs.netlify.app/.netlify/functions/add-to-cart', {
  //     method: 'POST',
  //     body: JSON.stringify({ cartId: localCartData.cartId, variantId: id }),
  //   });

  //   if (!result.ok) {
  //     console.error('There was a problem adding the item to the cart');
  //     return;
  //   }

  //   window.localStorage.setItem('jamstackconf:shopify:status', 'dirty');
  // }

  return (
    

    

    <div>

    
        
      <section className="product-page-content" ref={ref}>
        <div className="hide-screen" onClick={handleClose} style={{display: open === true ? "block": "none"}}>
        </div>
        
   


        
       
            
      
          <div className='product-page-image' style={{}}>
            <div style={{display: "flex", flexDirection: "column", padding: "30px", backgroundColor: "#343a40",color:"white", borderRadius:"20px"}} className="short-product-description">
              
            
            <div>
           

                {image.map((img) => (
                open === true ? 
                


               <div>
                  <div style={{display: loading ? "block" : "none"}}>
                    <CircularProgress sx={{color: "black"}}/>
                  </div>
                  
                      <div style={{display: loading ? "none" : "block", }}>
                        <img src={img} alt={img} width={"90vw"} 
                        className={open === true ? 'is-active': ''} 
                        style={{cursor: "pointer", border: "1px solid black", marginBottom: "0.935vw"}}
                        onClick={() => selectImage(img)}
                        onLoad={imageLoaded}/> 
                      </div>
                 
                  
                
                </div>
                  : null
                  ))
                }

            </div> 
               
    
              <img
                src={selectedImg !== undefined && open === true  ? selectedImg : image}
                alt={image}
                style={{marginBottom: open === true ? top : '', width: window.innerWidth <= 481 ? "55.5vw" : "30vw" }}
                className={open === true ? 'is-active': ''}
                onClick={handleOpen}
                />
          
         </div>
        </div>
        
      
      
          
        <Paper className={open === true ? 'is-active-product-description': ''} 
            elevation={3} 
            sx={{ 
            display: open === true ? "block": "none",
            marginTop: 0}}>
          <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={handleChangeTabs} aria-label="lab API tabs example">
                    <Tab label="Description" value="1" />
                    <Tab label="Science Facts" value="2" />
                  </TabList>
                </Box>
            <TabPanel value="1">
            
            
              <div className="product-copy">
              <h1>{product.title}</h1> 
              <div dangerouslySetInnerHTML={{__html: product.body_html}}>
                
              </div>
              <h2>{JSON.stringify(cost)};-  SEK </h2> 

              <VariantForm
                vars={product}
                current={product.id}
                pick={setChosenVariant}
                setQ={setQuantity}
                />
            
              </div>

              
                {/* <AgeRestriction /> */}
                
                <AddToCart buttonText="Add to Cart" variantId={productVariants.admin_graphql_api_id} options={[{title: productVariants.title, id: productVariants.admin_graphql_api_id}]} quantity={quantity}/>
                  
               
            
            </TabPanel>
            <TabPanel value="2">
              
              {product.title === 'BCAA Post Workout Powder (Honeydew/Watermelon)' ? <InformaticBCAA/> : null}
              {product.title === 'Bee Pearl Powder' ? <InformaticBEE/> : null}
              {product.title === 'Whey Protein (Salty Caramel Flavour)' ? <InformaticProtein/> : null}

            
            </TabPanel>
            
        </TabContext>
    </Box>


      <>
            {/* {product && (
              <select name="variant" className="options" onChange={handleChange}>
              
                  <option key={product.id} value={product.id}>
                    {product.title}
                  </option>
             
              </select>
            )} */}
      
      </>
   
          {/* <button className="button" onClick={addToCart}>
              Add to cart
          </button> */}

       
        
      </Paper>
      
        
    
      </section>

  
  </div>
     
  );
}